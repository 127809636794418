#menu-main-navigation .menu-item {
    text-align: left;
}

.inteco-logo-inner {
    max-width: 150px;
}

.inteco-navigation .sf-menu>li>a,
.inteco-navigation .sf-vertical>li>a {
    /* font-size: 18px; */
    font-size: 1.07vw;
}

.inteco-top-bar-social-icon .fa {
    /* font-size: 24px; */
    font-size: 1.44vw;
}

.inteco-header-right-social a {
    /* margin-left: 25px; */
    margin-left: 1.5vw;
}

@media only screen and (max-width: 767px) {
    .inteco-mobile-header .inteco-logo-inner {
        max-width: 100px;
    }

    .inteco-page-title-container {
        display: none;
    }
}

.slide-left {
    animation: 1s slide-left;
}

@keyframes slide-left {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: 0%;
    }
}

.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-logo, .inteco-animate-fixed-navigation.inteco-header-style-boxed .inteco-logo {
    padding-top: 8px;
    padding-bottom: 0px;
}
.inteco-animate-fixed-navigation.inteco-header-style-plain .inteco-logo-inner, .inteco-animate-fixed-navigation.inteco-header-style-boxed .inteco-logo-inner {
    max-width: 120px;
}
/* .inteco-navigation .sf-menu > li > a, .inteco-navigation .sf-vertical > li > a {
    font-size: 16px !important;
  }
@media only screen and (max-width: 700px) {
    .inteco-navigation .sf-menu > li > a, .inteco-navigation .sf-vertical > li > a {
        font-size: 16px !important;
      }
} */

@media (max-width: 1200px) { 
    /* .inteco-navigation .sf-menu > li > a, .inteco-navigation .sf-vertical > li > a {
        font-size: 16px !important;
      } */
}

@media only screen and (max-width: 1300px){
.inteco-header-right-social {
  display: block !important;
}
}
/* .inteco-body, .inteco-body p, .inteco-line-height, .gdlr-core-line-height {
    line-height: inherit;
  } */