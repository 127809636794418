.slick-prev {
    left: 3% !important;
    z-index: 1;
}
.slick-next {
    right: 3% !important;
    z-index: 1;
}
.slick-prev::before, .slick-next::before{
    color: black !important;
}
.slick-dots{
    bottom: 4px;
}
.gallery-nav-img{
    max-width: 150px;
    max-height: 100px;
    object-fit: cover;
}
.gallery-img{
    max-height: 550px;
    object-fit: inherit;
}
.nav .slick-current img{
    border: 2px solid black;
}

.nav .inteco-item-pdlr, .gdlr-core-item-pdlr{
    padding-left: 10px;
    padding-right: 10px;
}
