.contact-form .radio {
    padding: 10px 5px;
}
.contact-form .radio, .contact-form .radio input, .contact-form .radio label {
    cursor: pointer;
} 
.radio {
    display: inline-block;
    width: 100%;
    border: 1px solid hsla(0,0%,40%,.5);
    border-radius: 4px;
    margin: 0 0 15px;
    color: hsla(0,0%,40%,.5);
    font-size: 18px;
    font-family: ProximaNovaA-Semibold !important;
    font-weight: 400;
}
.position-relative {
    position: relative !important;
}
 .radio input {
    right: 15px;
    height: auto;
    top: 17px;
}
.say-hello-main .form-group input, .say-hello-main .form-group select, .say-hello-main .form-group textarea {
    color: #252b33;
}
.say-hello-main .form-group input, .say-hello-main .form-group select {
    height: 50px;
    border-radius: 4px;
    border: 1px solid #999;
    box-shadow: none;
    padding: 8px 15px;
    font-size: 17px;
}
.say-hello-main .form-group input, .say-hello-main .form-group select, .say-hello-main .form-group textarea {
    font-family: ProximaNovaA-Semibold !important;
}
.position-absolute {
    position: absolute !important;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.contact-form .radio label {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    padding-right: 30px;
    font-size: 14px;
    line-height: 26px;
    color: #252b33;/* Make All Time Selected Color*/
}
.contact-form .radio input:checked + label {
    color: #252b33;
}

.say-hello-main .form-group label {
    padding-left: 5px;
} 
.radio label {
    font-weight: bold;
}

.contact-form .radio input:checked + label .disable-check {
    display: none;
}
.contact-form .radio label img {
    height: 40px;
    margin-right: 5px;
}
.contact-form img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    vertical-align: middle;
}

.contact-form .radio input:checked + label .active-check {
    display: inline-block;
}
.contact-form .radio .active-check {
    display: none;
}

/* All time disable check hide and active check enable */
.active-check {
    display: inline-block !important;
}
.disable-check {
    display: none !important;
}
/* Custom Design */
.gdlr-core-input-wrap.gdlr-core-large textarea{
    padding: 10px;
}
.quote-user-form input,.quote-user-form textarea{
    border: 1px solid #666 !important;
}