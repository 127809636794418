.tp-bgimg.defaultimg{
    filter: opacity(50%);
}

.home-client .gdlr-core-gallery-list{
    display: flex;
}

.home-client .gdlr-core-lightgallery{
    margin: 0 auto;
}
#mobile-social-icon{
    display: block;
    text-align: center;
    padding-top: 20px;
}
/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    #mobile-social-icon{
        display: none;
    }
}
.gdlr-core-blog-grid-content-wrap{
    padding-top: 10px;
}
.gdlr-core-blog-grid .gdlr-core-blog-thumbnail
{
  margin-bottom: 15px !important;
}