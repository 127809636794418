.service-we-provide-img{
    margin:0px 0px 0px 30px;
    padding: 90px 20px 70px 0px;
}
.service-we-provide-text{
    padding: 140px 0px 0px 20px;
}

@media only screen and (max-width: 767px) {
    .service-we-provide-img{
        margin:0px 0px 0px 30px;
        padding: 90px 20px 20px 0px;
    }
    .service-we-provide-text{
        padding: 20px 0px 0px 20px;
    }
}